import api from './index';

interface Payload {
  email: string;
  name: string;
  message: string;
  userId: number;
}

export const submitContact = async (payload: Payload) => {
  return api.post('api/v1/contact', { payload });
};
