import * as React from 'react';
import { RadioGroup } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { GetPageStaticDataQuery } from 'types/generated/server';
import ModalSelectConference from 'components/ModalSelectConference';
import classNames from 'styles/classNames';
import { ScrollbarContainer } from './styles';

interface Props {
  teams: GetPageStaticDataQuery['teams'];
  conferences: GetPageStaticDataQuery['conferences'];
  setNewTeam: (value: string) => void;
  previousTeam?: string;
}

const SelectTeam: React.FC<Props> = ({ teams, conferences, setNewTeam, previousTeam }) => {
  const [activeTeam, setActiveTeam] = React.useState(previousTeam || '');
  const [isSelectConferenceOpen, setIsSelectConferenceOpen] = React.useState(false);
  const [selectedConference, setSelectedConference] = React.useState('');
  const teamObject = React.useMemo(() => {
    if (!activeTeam) {
      return null;
    }

    return teams.find((team) => team.id === activeTeam);
  }, [teams, activeTeam]);
  const conferenceObject = React.useMemo(() => {
    if (!selectedConference) {
      return null;
    }

    return conferences.find((conference) => conference.id === selectedConference);
  }, [conferences, selectedConference]);
  const visibleTeams = React.useMemo(() => {
    if (!selectedConference) {
      return teams;
    }

    return teams.filter((team) => team?.conferenceId === selectedConference);
  }, [teams, selectedConference, conferences]);

  React.useEffect(() => {
    setActiveTeam(previousTeam || '');
  }, [previousTeam]);

  return (
    <>
      <div className="flex h-full grow flex-col overflow-y-hidden">
        <div>
          <label className="label-base mb-2">Filter by Conference</label>
          <button
            type="button"
            onClick={() => setIsSelectConferenceOpen(true)}
            className="input-modal mb-4 inline-flex w-full items-center justify-between lg:mb-0"
          >
            <span className="inline-flex items-center">
              {!conferenceObject ? (
                'All Conferences'
              ) : (
                <span className="inline-flex items-center">
                  <img src={conferenceObject.logo} className="mr-2 h-[24px]" />{' '}
                  {conferenceObject.displayName}
                </span>
              )}
            </span>
            <ChevronDownIcon className="ml-2 h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-4 flex h-full grow flex-col overflow-y-hidden md:mt-6">
          <div className="mb-2 flex items-center justify-between">
            <label className="label-base">Select Team</label>
            <div className={classNames('items-center', !!teamObject ? 'hidden md:flex' : 'hidden')}>
              <img src={teamObject?.logo} className="mr-2 h-6" /> <span>{teamObject?.name}</span>
            </div>
          </div>
          <div className="flex h-full grow flex-col overflow-hidden rounded-2xl bg-color-bg-team-input pr-4 md:pr-8">
            <ScrollbarContainer className="flex h-full grow flex-col overflow-y-scroll rounded-2xl bg-color-bg-team-input py-4 pl-4 pr-4 md:py-6 md:pr-8 md:pl-6">
              <RadioGroup
                value={activeTeam}
                onChange={(value) => {
                  setActiveTeam(value);
                  setNewTeam(value);
                }}
              >
                <div className="space-y-2">
                  {visibleTeams.map((option) => {
                    const { name, id, logo } = option;

                    return (
                      <RadioGroup.Option
                        key={id}
                        value={id}
                        className={({ checked }) =>
                          classNames(
                            checked
                              ? 'bg-color-bg-lightmode-primary border-[#99F5A8] shadow-selected'
                              : 'border-transparent',
                            'flex h-[52px] cursor-pointer flex-col justify-center rounded-xl border px-4 focus:outline-none md:h-[64px] md:px-6 md:py-4',
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <div className="flex w-full items-center justify-between">
                            <div className="flex items-center">
                              <img src={logo} className="mr-4 w-6 md:mr-6 md:w-8" />
                              <RadioGroup.Label className="block cursor-pointer text-base font-medium leading-none md:text-lg">
                                {name}
                              </RadioGroup.Label>
                            </div>
                            <div>{checked && <img src="/app/check.svg" />}</div>
                          </div>
                        )}
                      </RadioGroup.Option>
                    );
                  })}
                </div>
              </RadioGroup>
            </ScrollbarContainer>
          </div>
        </div>
      </div>
      <ModalSelectConference
        isOpen={isSelectConferenceOpen}
        handleClose={() => setIsSelectConferenceOpen(false)}
        conferences={conferences}
        handleSelectConference={(conferenceId) => {
          setSelectedConference(conferenceId);
          setIsSelectConferenceOpen(false);
        }}
      />
    </>
  );
};

export default SelectTeam;
