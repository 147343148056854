import * as React from 'react';
import classNames from 'styles/classNames';

interface Props {
  title: string;
  description: string;
  index: number;
}

const Faq: React.FC<Props> = ({ title, description, index }) => {
  const [isOpen, setIsOpen] = React.useState(index === 0);

  return (
    <div
      className={classNames(
        'flex w-full cursor-pointer flex-col rounded-2xl p-8',
        isOpen && 'border-2 border-[#1BD5F3] bg-color-bg-primary shadow-faq',
      )}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="flex w-full">
        <div className="w-full">
          <h3 className="text-xl font-semibold">{title}</h3>
          <p
            className={classNames(
              'mt-3 text-base text-color-text-muted',
              isOpen ? 'hidden lg:block' : 'hidden',
            )}
          >
            {description}
          </p>
        </div>
        <div className="ml-6 shrink-0">
          <img
            src="/app/faq-minus.svg"
            className={classNames(isOpen ? 'block' : 'hidden')}
            alt="close"
          />
          <img
            src="/app/faq-plus.svg"
            className={classNames(isOpen ? 'hidden' : 'block')}
            alt="open"
          />
        </div>
      </div>
      <p
        className={classNames(
          'mt-3 text-base text-color-text-muted',
          isOpen ? 'block lg:hidden' : 'hidden',
        )}
      >
        {description}
      </p>
    </div>
  );
};

export default Faq;
