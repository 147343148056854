export const LEADERBOARD_ID = 'leaderboard';
export const LEADERBOARD_ID_LINK = `#${LEADERBOARD_ID}`;
export const FORM_ID = 'lets-talk';
export const FORM_ID_LINK = `#${FORM_ID}`;
export const FAN_RACE = 1000;
export const STORED_ID_KEY = 'fanId';
export const REF_ID_KEY = 'refId';
export const REF_USERNAME_KEY = 'refUsername';

export const HTTP_POST = 'POST';

export const faqs = [
  {
    title: 'When is Arena launching?',
    description:
      'Arena is live! Check out the leaderboard to see how many fans are joining their team in real time. Arena NIL donations will launch in early 2023.',
  },
  {
    title: 'How does Arena work with recruits and the transfer portal?',
    description:
      'Donations are distributed monthly to players actively on the roster. Recruits and transfers will be able to see how much they would earn by attending a school, but can only benefit from Arena NIL once they are on the roster. Our goal is to provide maximum transparency into NIL activities for fans and players alike, allowing them to make the best decision for their school destination.',
  },
  {
    title: 'What are athletes saying about equal distributions?',
    description:
      'It was their idea! After speaking with numerous athletes, we learned that equal distribution was preferable for multiple reasons. It allows players to see earning potential, it’s more inclusive and builds comradery, it allows players to focus more on being a student athlete rather than sourcing deal after deal. The list goes on!',
  },
  {
    title: 'How is Arena unique?',
    description:
      'We put the fans and players first. We aren’t a collective raising tons of money and then making decisions behind the scenes. Fans deserve a better way to engage with and contribute to their favorite teams. Players need a more transparent way to evaluate fan bases and earning potential.',
  },
  {
    title: 'What data is public and transparent?',
    description:
      'Everything is transparent, except how much individuals contributed personally. Everyone will be able to see the dollar amount that each fanbase has contributed as a whole and athletes will know exactly what they will earn. We will provide athletes with what they need to be compliant with the IRS, and universities and the NCAA access to the data to continue to push for more transparent accounting in college sports. We are also exploring options to enable fans to make some donations tax-refundable, so stay tuned for our NIL Bank launch in 2023.',
  },
  {
    title: 'How are NIL funds distributed?',
    description:
      'Funds are distributed equally to every player on the team by setting up irect deposit for their NIL payments through Arena. Payments occur on a monthly basis to players enrolled, on the active roster, and not in the transfer portal. There will be lockup periods around key dates such as transfer windows. There is no internal competition, and no one is left out. We empower athletes to focus on their game and not waste energy trying to find deals.',
  },
  {
    title: 'Is Arena compliant?',
    description:
      'Our model is fully compliant with NIL regulations and will be updated real time as regulations and state laws change.',
  },
  {
    title: 'Will Arena be available for all sports?',
    description:
      'Yes, every sport at every school will have its own separate NIL Bank. Each player will receive equal donations form the fans who donate to that team and sport’s NIL Bank.',
  },
  {
    title: 'What does the future of Arena look like?',
    description:
      "We are redesigning the fan experience. In the future, Arena will be the one-stop shop for all things college sports. Arena will have a community and player access you can't find anywhere else. Stay tuned.",
  },
];
