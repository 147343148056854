import * as React from 'react';
import { Dialog } from '@headlessui/react';
import * as Sentry from '@sentry/nextjs';
import { submitLogin } from 'services/client/api/submitLogin';
import Spinner from 'svg/Spinner';
import Modal from 'components/Modal';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleLogin: (data: any) => void;
}

const ModalLogin: React.FC<Props> = ({ isOpen, handleClose, handleLogin }) => {
  const [email, setEmail] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Modal
      className="w-[92vw] max-w-[480px]"
      isOpen={isOpen}
      handleClose={() => {
        if (isLoading) {
          return;
        }
        handleClose();
      }}
    >
      <div className="relative flex h-full grow flex-col overflow-y-auto py-16 px-6 lg:px-14 lg:pt-10 lg:pb-14">
        <div className="mt-4 mb-12 flex justify-center">
          <img src="/images/logo.svg" />
        </div>
        <Dialog.Title
          as="h3"
          className="text-center text-3xl font-bold leading-[120%] lg:text-[56px]"
        >
          Log in
        </Dialog.Title>
        <p className="mx-auto mt-4 max-w-[280px] text-center text-base text-color-text-paragraph md:max-w-none lg:my-6 lg:text-xl">
          Use the email you signed up with to look up your stats, retrieve your invite link, and
          help your team win.
        </p>
        <div className="mt-12">
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              if (isLoading) {
                return;
              }

              setIsLoading(true);

              try {
                const user = await submitLogin({ email });
                setIsLoading(false);
                handleLogin(user);
              } catch (error) {
                Sentry.captureException(error);
              }
            }}
            className="w-full"
          >
            <input
              placeholder="Email"
              className="input-modal block w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              type="email"
              autoComplete="email"
              disabled={isLoading}
            />
            <div className="flex w-full justify-end">
              <button
                disabled={isLoading}
                className="button-large-primary mt-2 flex w-full shrink-0 justify-center text-center md:w-auto"
              >
                {isLoading ? <Spinner /> : 'Log in'}
              </button>
            </div>
          </form>
        </div>
        <div className="absolute right-6 top-6 lg:right-8 lg:top-8">
          <button
            type="button"
            onClick={() => {
              if (isLoading) {
                return;
              }
              handleClose();
            }}
          >
            <img src="/app/close-icon.svg" />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalLogin;
