import * as React from 'react';
import { Dialog } from '@headlessui/react';
import { GetPageStaticDataQuery } from 'types/generated/server';
import Modal from 'components/Modal';
import SelectConference from 'components/SelectConference';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  conferences: GetPageStaticDataQuery['conferences'];
  handleSelectConference: (conferenceId: string) => void;
}

const ModalSelectConference: React.FC<Props> = ({
  isOpen,
  handleClose,
  conferences,
  handleSelectConference,
}) => {
  return (
    <Modal className="max-w-[620px]" isOpen={isOpen} handleClose={handleClose}>
      <div className="relative flex h-full grow flex-col overflow-y-auto py-6 px-6 lg:px-16 lg:py-12">
        <div className="mb-8 flex items-center">
          <Dialog.Title as="h3" className="text-xl font-bold leading-[120%] lg:text-[32px]">
            Filter by Conference
          </Dialog.Title>
        </div>
        <div className="absolute right-12 top-8">
          <button type="button" onClick={handleClose}>
            <img src="/app/close-icon.svg" />
          </button>
        </div>
        <SelectConference
          conferences={conferences}
          handleSelectConference={handleSelectConference}
        />
      </div>
    </Modal>
  );
};

export default ModalSelectConference;
