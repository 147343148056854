import api from './index';

interface Payload {
  conference: string;
  destinationUrl: string;
  referralFanId: number;
  referralFanUsername: string;
}

export const submitReferralClick = async (payload: Payload) => {
  return api.post('api/v1/log/referral-click', { payload });
};
