import { getPageStaticData } from 'services/server/graphql/queries/getPageStaticData';
import Home from 'screens/Home';
import { StaticProps } from 'screens/Home/props';

export async function getStaticProps() {
  const response = await getPageStaticData();
  const { conferences, teams } = response;
  const props: StaticProps = { teams, conferences };

  return {
    props,
  };
}

export default Home;
