import styled from 'styled-components';

export const ScrollbarContainer = styled.div`
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
    margin-right: 16px;
    padding-right: 16px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #0c1529;
    border-radius: 4px;
    margin-top: 24px;
    margin-bottom: 24px;
    margin-right: 16px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #404d6a;
    border-radius: 4px;
  }
`;
