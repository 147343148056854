import api from './index';

interface Payload {
  fanId: number;
  pathname: string;
  queryString: string;
  team: string;
  email: string;
}

export const submitAppPing = async (payload: Payload) => {
  return api.post('api/v1/log/ping', { payload });
};
