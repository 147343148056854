import api from './index';

interface Payload {
  email: string;
  name: string;
  teamId: string;
  isAthlete?: boolean;
  referralUserId?: string;
}

export const submitJoin = async (payload: Payload) => {
  return api.post('api/v1/join', {
    payload,
  });
};
