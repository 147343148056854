import * as React from 'react';
import { Dialog } from '@headlessui/react';
import * as Sentry from '@sentry/nextjs';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
import QRCode from 'react-qr-code';
import { generateShareUrl } from 'utils/generateShareUrl';
import Modal from 'components/Modal';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  fanId: number;
  teamLogo: string;
  teamName: string;
  teamMascot: string;
  username?: string;
  conferenceSlug: string;
  teamFanCount: number;
  clicks: number;
  signups: number;
}

const ModalShare: React.FC<Props> = ({
  isOpen,
  handleClose,
  teamLogo,
  teamName,
  teamMascot,
  conferenceSlug,
  fanId,
  username,
  teamFanCount,
  clicks,
  signups,
}) => {
  const shareUrl = generateShareUrl({ username, fanId, conferenceSlug });

  return (
    <Modal className="w-[92vw] max-w-[800px]" isOpen={isOpen} handleClose={handleClose}>
      <div className="relative flex h-full grow flex-col overflow-y-auto py-6 px-6 lg:px-14 lg:pt-10 lg:pb-14">
        <Dialog.Title
          as="h3"
          className="text-center text-xl font-bold leading-[120%] md:text-[56px]"
        >
          Share Arena
        </Dialog.Title>
        <div className="absolute right-4 top-4 lg:right-12 lg:top-8">
          <button type="button" onClick={handleClose}>
            <img src="/app/close-icon.svg" />
          </button>
        </div>
        <p className="my-4 mx-auto max-w-[280px] text-center text-base text-color-text-paragraph md:max-w-none lg:my-6 lg:text-2xl">
          Beat your rivals, help {teamName} win
        </p>
        <div className="mx-auto flex max-w-[640px] flex-col items-center">
          <div className="flex flex-col space-y-4 text-base md:flex-row md:space-y-0 md:space-x-12 md:text-2xl">
            <div className="flex flex-col">
              <div className="mr-4 inline-flex items-center font-semibold">
                <img src={teamLogo} className="mr-2 h-6" /> <span>{teamName}</span>
              </div>
              <div>
                <span className="text-shadow-live text-[#75ECFF]">{teamFanCount}</span>{' '}
                <span className="text-color-text-paragraph">Total Fans</span>
              </div>
            </div>
            <div>
              <div className="font-semibold">Your contributions</div>
              <div className="flex space-x-6">
                <div>
                  <span className="text-shadow-live text-[#75ECFF]">{clicks}</span>{' '}
                  <span className="text-color-text-paragraph">Clicks</span>
                </div>
                <div>
                  <span className="text-shadow-live text-[#75ECFF]">{signups}</span>{' '}
                  <span className="text-color-text-paragraph">Signups</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 mb-4 rounded-xl bg-white p-4 md:mb-10 md:mt-14">
            <QRCode value={`https://${shareUrl}`} size={128} />
          </div>
        </div>
        <div>
          <div className="flex w-full flex-col md:flex-row md:space-x-5">
            <div className="w-full rounded-2xl border border-color-text-muted px-4 py-4 text-sm md:px-6 md:py-[18px] md:text-base">
              {shareUrl}
            </div>
            <button
              className="button-small-primary mt-2 h-[52px] shrink-0 md:mt-0 md:h-auto"
              onClick={() => {
                try {
                  copy(`https://${shareUrl}`);
                  toast.success('Invite link copied');
                } catch (error) {
                  Sentry.captureException(error);
                  toast.error('Could not copy');
                }
              }}
            >
              Copy Invite Link
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalShare;
