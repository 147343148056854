import * as React from 'react';
import { Dialog } from '@headlessui/react';
import { GetPageStaticDataQuery } from 'types/generated/server';
import Modal from 'components/Modal';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const ModalLearnMore: React.FC<Props> = ({ isOpen, handleClose }) => {
  return (
    <Modal className="max-w-[700px]" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex h-full grow flex-col py-6 px-6 lg:py-10 lg:px-16">
        <div className="mb-8 flex items-center justify-between">
          <Dialog.Title
            as="h3"
            className="text-center text-xl font-bold leading-[120%] lg:text-[32px]"
          >
            The Future of NIL
          </Dialog.Title>
          <div>
            <button type="button" onClick={handleClose}>
              <img src="/app/close-icon.svg" />
            </button>
          </div>
        </div>
        <div className="space-y-4 text-lg">
          <div>
            NIL is great for players, but comes with its challenges. After talking to numerous
            college athletes, some of the biggest pain points we’ve heard is the lack of
            transparency into their earning potential and how much work it requires to manage all
            their NIL activities.
          </div>
          <div>
            Our solution: distributing NIL funds equally across each player on the team, giving
            athletes an easy way to track how much they’ll earn,{' '}
            <span className="text-[#22D3EE]">
              empowering them to spend more time on what matters - winning games.
            </span>
          </div>
          <div>
            This is a better experiences for the transfer portal and recruiting as well. Instead of
            figuring out contracts behind the scenes, an athlete can evaluate their exact earning
            potential at any school with Arena's transparent data and equal distributions. It is
            effortless for an athlete to make the best decision on where to play, without any of the
            pain of the current system.
          </div>
        </div>
        <div className="mt-8 flex w-full justify-end">
          <button
            className="button-small-primary py-4 px-10"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalLearnMore;
