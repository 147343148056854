import styled from 'styled-components';

export const GradientBackground = styled.div`
  background: radial-gradient(
      50% 50% at 50% 50%,
      #ecf2f2 0%,
      #d4eeec 17.44%,
      #54d2e3 62.28%,
      #0184ad 100%
    ),
    #d9d9d9;
  filter: blur(150px);
`;

export const ScrollbarContainer = styled.div`
  scroll-padding-right: 10px;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 16px;
    padding-right: 16px;
    background: trasparent;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #0c1529;
    border-radius: 4px;
    margin-right: 16px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #404d6a;
    border-radius: 4px;
  }
`;

export const LeaderboardGridRow = styled.div`
  grid-template-columns: 80px minmax(0, 1.5fr) minmax(0, 1.2fr) repeat(2, minmax(0, 1fr));
  grid-column-gap: 0.5rem;
`;

export const CalloutGradientBackground = styled.div`
  background: linear-gradient(180deg, #060c18 0%, #131f3a 100%);
`;
