import * as React from 'react';
import { submitContact } from 'services/client/api/submitContact';
import Spinner from 'svg/Spinner';

const ContactForm: React.FC<{ fanId?: number }> = ({ fanId }) => {
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <div className="w-full pt-8 pb-20">
      <div className="flex justify-center">
        <img src="/images/form-fingers.svg" alt="form-fingers" />
      </div>
      <h2 className="mx-auto mb-8 max-w-[486px] text-center text-[3rem] font-bold leading-[120%] text-color-text-inverted-primary lg:text-[3rem]">
        We'd love to hear from you
      </h2>
      {isFormSubmitted ? (
        <div className="pt-4">
          <div className="text-6xl">✅</div>
          <div className="mt-4 max-w-[560px] text-4xl font-bold leading-[150%] text-color-text-inverted-primary">
            Thank you for contacting Arena! We will respond shortly.
          </div>
        </div>
      ) : (
        <form
          onSubmit={async (e) => {
            e.preventDefault();

            if (isLoading) {
              return;
            }

            setIsLoading(true);

            setIsFormSubmitted(true);
            try {
              const resp = await submitContact({ name, email, message, userId: fanId });
              setIsLoading(false);
              console.log(resp);
            } catch (error) {
              console.log(error);
            }
            return;
          }}
          id="contact-form"
          className="w-full space-y-8"
        >
          <div>
            <label
              className="sr-only mb-[5px] block font-medium text-color-text-muted"
              htmlFor="name"
            >
              Name <span className="text-xs text-red-500">*</span>
            </label>
            <div>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                id="name"
                name="name"
                autoComplete="name"
                className="input-form-inverted"
                required
                disabled={isLoading}
              />
            </div>
          </div>
          <div>
            <label
              className="sr-only mb-[5px] block font-medium text-color-text-muted"
              htmlFor="email"
            >
              Email <span className="text-xs text-red-500">*</span>
            </label>
            <div>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                id="email"
                name="email"
                autoComplete="email"
                type="email"
                className="input-form-inverted"
                required
                disabled={isLoading}
              />
            </div>
          </div>
          <div>
            <label
              className="sr-only mb-[5px] block font-medium text-color-text-muted"
              htmlFor="comment"
            >
              Anything to add?
            </label>
            <div>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Message"
                id="comment"
                name="comment"
                className="input-form-inverted resize-y"
                rows={6}
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="text-color-text-inverted-primary">
            Typical response time: 10 minutes or less
          </div>
          <div className="flex items-center justify-between">
            <div className="text-color-text-inverted-primary">team@arenanil.io</div>
            <button
              disabled={isLoading}
              className="button-large-inverted"
              type="submit"
              id="contact-submit"
            >
              {isLoading ? <Spinner /> : 'Submit'}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
