import * as React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'styles/classNames';

interface Props {
  isOpen: boolean;
  handleClose: (value: boolean) => void;
  className?: string;
  positionBottomDesktop?: boolean;
  children: React.ReactNode;
}

// NOTE: How to handle widths and max widths??
const Modal: React.FC<Props> = ({
  isOpen,
  handleClose,
  className,
  positionBottomDesktop,
  children,
}) => {
  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-70" onClose={handleClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-70 bg-gray-800 bg-opacity-90 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-70 overflow-y-auto">
          <div
            className={classNames(
              'flex min-h-full items-end justify-center',
              positionBottomDesktop ? 'sm:items-end' : 'sm:items-center',
            )}
          >
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="flex w-full justify-center">
                <Dialog.Panel
                  className={classNames(
                    'relative flex h-full max-h-[90vh] w-full grow transform flex-col overflow-hidden rounded-t-3xl border-2 border-[#98F5A9] bg-color-bg-modal  shadow-modal transition-all sm:w-full sm:rounded-xl',
                    !!className && className,
                  )}
                >
                  {children}
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
