import * as React from 'react';
import { GetPageStaticDataQuery } from 'types/generated/server';

interface Props {
  conferences: GetPageStaticDataQuery['conferences'];
  handleSelectConference: (conferenceSluh: string) => void;
}

const SelectTeam: React.FC<Props> = ({ conferences, handleSelectConference }) => {
  return (
    <div className="-ml-3 flex h-full grow flex-col">
      <div className="relative grid gap-2 lg:grid-cols-2">
        {conferences.map((item) => (
          <button
            key={item.id}
            className="flex items-center rounded-lg p-3 transition duration-150 ease-in-out hover:bg-brand-gray-850"
            onClick={() => handleSelectConference(item.id)}
          >
            <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md sm:h-12 sm:w-12">
              <img src={item.logo} alt={item.fullName} />
            </div>
            <div className="ml-4">
              <p className="text-base font-medium text-color-text-primary">{item.displayName}</p>
            </div>
          </button>
        ))}
        <button
          onClick={() => handleSelectConference('')}
          className="flex items-center rounded-lg p-3 transition duration-150 ease-in-out hover:bg-brand-gray-850"
        >
          <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md sm:h-12 sm:w-12">
            <svg
              width="26"
              height="18"
              viewBox="0 0 26 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.6827 17.3105L16.7316 15.2867H8.59153L7.63407 17.3105H0.111328L7.47694 0.619141H17.8657L25.2317 17.3105H17.6827ZM10.0899 10.8298H15.2452L12.8272 5.78574H12.5114L10.0899 10.8298Z"
                fill="#1BD5F3"
              />
            </svg>
          </div>
          <div className="ml-4">
            <p className="text-base font-medium text-color-text-primary">View All Teams</p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default SelectTeam;
