interface Parmas {
  fanId: number;
  conferenceSlug: string;
  username?: string;
}

export const generateShareUrl = ({ fanId, conferenceSlug, username }: Parmas) => {
  const shareQuery = username ? `fan=${username}` : `fanId=${fanId}`;
  const shareUrl = `${process.env.APP_DOMAIN}?${shareQuery}&c=${conferenceSlug}`;

  return shareUrl;
};
