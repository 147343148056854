import * as React from 'react';
import { Dialog } from '@headlessui/react';
import * as Sentry from '@sentry/nextjs';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
import { generateShareUrl } from 'utils/generateShareUrl';
import Modal from 'components/Modal';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  fanId: number;
  teamLogo: string;
  teamName: string;
  teamMascot: string;
  username?: string;
  conferenceSlug: string;
}

const ModalSuccess: React.FC<Props> = ({
  isOpen,
  handleClose,
  teamLogo,
  teamName,
  teamMascot,
  conferenceSlug,
  fanId,
  username,
}) => {
  const shareUrl = generateShareUrl({ username, fanId, conferenceSlug });

  return (
    <Modal className="w-[92vw] max-w-[920px]" isOpen={isOpen} handleClose={handleClose}>
      <div className="relative flex h-full grow flex-col overflow-y-auto py-6 px-6 lg:px-28 lg:pt-10 lg:pb-14">
        <Dialog.Title
          as="h3"
          className="bg-gradient-to-r from-[#A3F7A1] to-[#8EDBE0] bg-clip-text text-center text-3xl font-bold leading-[1.2] text-transparent md:text-[64px]"
        >
          Congratulations
        </Dialog.Title>
        <div className="absolute right-4 top-4 lg:right-12 lg:top-8">
          <button type="button" onClick={handleClose}>
            <img src="/app/close-icon.svg" />
          </button>
        </div>
        <p className="my-4 mx-auto max-w-[280px] text-center text-lg text-color-text-paragraph md:max-w-none lg:my-6 lg:text-2xl">
          You have successfully registered as a fan of
        </p>
        <div className="text-center">
          <img src={teamLogo} className="mx-auto" />
        </div>
        <p className="mt-0 text-center text-xl font-medium md:text-2xl">
          {teamName} {teamMascot}
        </p>
        {/* <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="mt-4 md:mt-8"
        >
          <label className="mb-2 block text-color-text-paragraph">
            Personalize Arena by adding your username
          </label>
          <div className="flex w-full flex-col md:flex-row md:space-x-5">
            <input className="input-modal w-full" type="text" placeholder="Add a username" />
            <button className="button-small-primary mt-2 h-[52px] md:mt-0 md:h-auto">Submit</button>
          </div>
        </form> */}
        <div>
          <p className="mx-auto mt-8 mb-2 max-w-[480px] text-center text-base text-color-text-paragraph md:mb-4 md:text-xl">
            Beat your rivals and win signing day by sharing Arena with {teamName} fans
          </p>
          <div className="flex w-full flex-col md:flex-row md:space-x-5">
            <div className="w-full rounded-2xl border border-color-text-muted px-4 py-4 text-sm md:px-6 md:py-[18px] md:text-base">
              {shareUrl}
            </div>
            <button
              className="button-small-primary mt-2 h-[52px] shrink-0 md:mt-0 md:h-auto"
              onClick={() => {
                try {
                  copy(shareUrl);
                  toast.success('Invite link copied');
                } catch (error) {
                  Sentry.captureException(error);
                  toast.error('Could not copy');
                }
              }}
            >
              Copy Invite Link
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSuccess;
