import * as React from 'react';
import styled from 'styled-components';
import { FAN_RACE } from 'utils/constants';

interface Props {
  fanCount: number;
}

const METER_HEIGHT = 8;
const METER_MULTIPLY_LENGTH = 10;

const MeterBackground = styled.div`
  background: #0e131f;
  width: 100%;
  height: ${METER_HEIGHT}px;
  border-radius: 4px;
`;

export const Meter = styled.div<{ fanCount: number }>`
  background: linear-gradient(270deg, #7dd6df 14.14%, #a3f7a1 91.84%);
  height: ${METER_HEIGHT}px;
  width: ${({ fanCount }) => (fanCount / FAN_RACE) * 100}%;
  border-radius: 4px;
  box-shadow: 0px 0px 12px rgba(163, 247, 161, 0.8);
`;

const LeaderboardMeter: React.FC<Props> = ({ fanCount }) => {
  return (
    <div>
      <MeterBackground>
        <Meter fanCount={(fanCount + 1) * METER_MULTIPLY_LENGTH} />
      </MeterBackground>
    </div>
  );
};

export default LeaderboardMeter;
