import * as React from 'react';
import { Dialog } from '@headlessui/react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';
import { GetPageStaticDataQuery } from 'types/generated/server';
import { submitJoin } from 'services/client/api/submitJoin';
import { REF_ID_KEY } from 'utils/constants';
import Spinner from 'svg/Spinner';
import Modal from 'components/Modal';
import SelectTeam from 'components/SelectTeam';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleSuccess: (data: any) => void;
  setTeam: (value: string) => void;
  isAthlete?: boolean;
  teams: GetPageStaticDataQuery['teams'];
  conferences: GetPageStaticDataQuery['conferences'];
  team: string;
  existingName: string;
  existingEmail: string;
  setExistingName: (value: string) => void;
  setExistingEmail: (value: string) => void;
}

const ModalJoin: React.FC<Props> = ({
  isOpen,
  handleClose,
  handleSuccess,
  setTeam,
  isAthlete,
  teams,
  conferences,
  team,
  existingName,
  existingEmail,
  setExistingName,
  setExistingEmail,
}) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = React.useState(false);
  const [newTeam, setNewTeam] = React.useState(team);
  const [name, setName] = React.useState(existingName || '');
  const [email, setEmail] = React.useState(existingEmail || '');

  React.useEffect(() => {
    setName(existingName || '');
    setEmail(existingEmail || '');
  }, [existingName, existingEmail]);

  return (
    <Modal
      className="max-w-[700px]"
      isOpen={isOpen}
      handleClose={() => {
        if (isLoading) {
          return;
        }
        handleClose();
      }}
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          if (isLoading) {
            return;
          }

          setIsLoading(true);

          if (!team && !newTeam) {
            toast.error('Please select a team');
            return;
          }

          setTeam(newTeam);
          setExistingName(name);
          setExistingEmail(email);

          let referralUserId;
          try {
            referralUserId = router.query.fanId || localStorage.getItem(REF_ID_KEY) || undefined;
          } catch (error) {
            Sentry.captureException(error);
          }

          let response;
          try {
            response = await submitJoin({
              teamId: newTeam,
              name,
              email,
              isAthlete,
              referralUserId,
            });
          } catch (error) {
            Sentry.captureException(error);
          }

          setIsLoading(false);
          handleClose();
          handleSuccess(response);
        }}
        className="relative flex h-full grow flex-col overflow-hidden py-6 px-6 lg:py-8 lg:px-12"
      >
        <div className="mb-8 flex items-center justify-between">
          <Dialog.Title
            as="h3"
            className="text-center text-xl font-bold leading-[120%] lg:text-[32px]"
          >
            Join Arena
          </Dialog.Title>
        </div>
        <div className="mb-4 flex space-x-2 md:mb-6 md:space-x-6">
          <input
            required
            autoComplete="name"
            placeholder="Your name"
            className="input-modal w-1/2"
            value={name}
            disabled={isLoading}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <input
            required
            autoComplete="email"
            placeholder="Your email"
            className="input-modal w-1/2"
            type="email"
            value={email}
            disabled={isLoading}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <SelectTeam
          previousTeam={team}
          setNewTeam={setNewTeam}
          teams={teams}
          conferences={conferences}
        />
        <div className="mt-4 flex w-full justify-end md:mt-6">
          <button disabled={isLoading} type="submit" className="button-small-primary py-4 px-10">
            {isLoading ? <Spinner /> : 'Join Arena'}
          </button>
        </div>
        <div className="absolute right-6 top-6 lg:right-12 lg:top-8">
          <button
            type="button"
            onClick={() => {
              if (isLoading) {
                return;
              }
              handleClose();
            }}
          >
            <img src="/app/close-icon.svg" />
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalJoin;
