import * as React from 'react';
import { Dialog } from '@headlessui/react';
import { GetPageStaticDataQuery } from 'types/generated/server';
import Modal from 'components/Modal';
import SelectTeam from 'components/SelectTeam';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  setTeam: (value: string) => void;
  teams: GetPageStaticDataQuery['teams'];
  conferences: GetPageStaticDataQuery['conferences'];
  team: string;
}

const ModalSelectTeam: React.FC<Props> = ({
  isOpen,
  handleClose,
  setTeam,
  teams,
  conferences,
  team,
}) => {
  const [newTeam, setNewTeam] = React.useState(team);

  return (
    <Modal className="max-w-[700px]" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex h-full grow flex-col overflow-hidden py-6 px-6 lg:py-10 lg:px-16">
        <div className="mb-8 flex items-center justify-between">
          <Dialog.Title
            as="h3"
            className="text-center text-xl font-bold leading-[120%] lg:text-[32px]"
          >
            Select Team
          </Dialog.Title>
          <div>
            <button type="button" onClick={handleClose}>
              <img src="/app/close-icon.svg" />
            </button>
          </div>
        </div>
        <SelectTeam teams={teams} conferences={conferences} setNewTeam={setNewTeam} />
        <div className="mt-8 flex w-full justify-end">
          <button
            className="button-small-primary py-4 px-10"
            onClick={() => {
              setTeam(newTeam);
              handleClose();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSelectTeam;
